import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

const About = () => {
	// const baseUrl = process.env.PUBLIC_URL;
	const one = (
		<p>
			I am currently a <b>Systems Developer</b> at
			<a href="https://www.hisp.tz/"> HISP Tanzania</a>, working in the
			Development department.
			{/* At the same time, I am undertaking a
        part-time <b> Master's of Science</b> in <b>Software Engineering </b>at{" "}
        <a href="https://www.ox.ac.uk/about">University of Oxford</a>. */}
		</p>
	);
	const two = (
		<p>
			Outside of work, I'm interested in following the developments of
			technology. I also play a lot of video games.
		</p>
	);

	const tech_stack = [
		"Typescript",
		"Dart",
		"React.js",
		"Flutter",
		"Javascript ES6+",
		"DHIS2",
	];

	return (
		<div id="about">
			<FadeInSection>
				<div className="section-header ">
					<span className="section-title">/ about me</span>
				</div>
				<div className="about-content">
					<div
						className="about-description"
						style={{ maxWidth: "100%" }}
					>
						{[one]}
						{"Here are some technologies I have been working with:"}
						<ul className="tech-stack">
							{tech_stack.map(function (tech_item, i) {
								return (
									<FadeInSection delay={`${i + 1}00ms`}>
										<li>{tech_item}</li>
									</FadeInSection>
								);
							})}
						</ul>
						{[two]}
					</div>
					{/* <div className="about-image">
						<img
							alt="Dave"
							src={baseUrl + "/assets/me2.jpg"}
						/>
					</div> */}
				</div>
			</FadeInSection>
		</div>
	);
};

export default About;
