import React from "react";
import "../styles/Credits.css";
import FadeInSection from "./FadeInSection";
import { DateTime } from "luxon";

const Credits: React.FC = () => {
	const currentYear = DateTime.now().year;

	return (
		<FadeInSection>
			<div id="credits">
				<div className="ending-credits">
					<div>Developed by Davis Tibenda.</div>
					<div>All rights reserved. ©{currentYear}</div>
				</div>
			</div>
		</FadeInSection>
	);
};

export default Credits;
