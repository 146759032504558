import React from "react";

export default function FadeInSection(props: any) {
	const [isVisible, setVisible] = React.useState(false);
	const domRef: any = React.useRef();
	React.useEffect(() => {
		const observer: any = new IntersectionObserver((entries: any) => {
			entries.forEach((entry: any) => {
				if (entry.isIntersecting) {
					setVisible(entry.isIntersecting);
				}
			});
		});
		const { current } = domRef;
		if (current) {
			observer.observe(current);
		}
		return () => {
			if (current) {
				observer.unobserve(current);
			}
		};
	}, []);
	return (
		<div
			className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
			style={{ transitionDelay: `${props.delay}` }}
			ref={domRef}
		>
			{props.children}
		</div>
	);
}
