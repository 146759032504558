import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	if (isHorizontal) {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	} else {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
	if (isHorizontal) {
		return {
			id: `full-width-tab-${index}`,
			"aria-controls": `full-width-tabpanel-${index}`,
		};
	} else {
		return {
			id: `vertical-tab-${index}`,
		};
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: "theme.palette.background.paper",
		display: "flex",
		height: 500,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

const JobList = () => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const experienceItems: any = {
		"HISP Tanzania": {
			jobTitle: "Systems Developer @",
			link: "https://hisp.tz/",
			duration: "JULY 2023 - PRESENT",
			desc: [
				"Developed a react web app using the DHIS2 Library called the etls-dat-app which is used to track adherence of patients in various frequencies such as Daily, Weekly and Monthly.",
				"Developed mobile apps for various projects in the company such as the BHI mobile app which is used to collect health data in rural areas of South Sudan and stored in a DHIS2 instance server.",
				"Developed report web apps for data analysis support in various projects.",
				"Created various Flutter components with class constructors to re-use throughout the development of the mobile apps.",
				"Implemented responsiveness of the mobile app in different screen-sized devices such as Tablet and Mobile devices",
				"Maintained various projects (Both web and mobile apps) by upgrading packages and fixing bugs accordingly.",
			],
		},
		"SkyConnect Inc": {
			jobTitle: "Software Developer @",
			link: "https://skyconnect.co.tz/",
			duration: "SEPT 2022 - JUL 2023",
			desc: [
				"Developed the various basic modules for a Hospital ERP such as Reception, Human Resource (HR), Laboratory, In/Out-Patient Management, Pharmacy, Inventory Management etc",
				"Developed the various basic modules for a school ERP such as Students, Employees, Class, Subjects, Grades, Exam, Library, e-Resource etc.",
				"Implemented multiple UI pages for the Hospital ERP and School ERP software using Flutter",
				"Implemented responsiveness of the ERP softwares in different screen-sized devices such as Laptop, Tablet and Mobile devices.",
				"Created various Flutter components with class constructors to re-use throughout the development of the ERP software.",
			],
		},
		"Code Block": {
			jobTitle: "Software Developer Intern @",
			link: "https://codeblock.co.tz/",
			duration: "JUL 2021 - SEPT 2021",
			desc: [
				"Developed an API to receive and send HTTP request using NestJS framework.",
				"Installed TypeORM package to configure MySQL database with the NestJS API",
				"Developed a mobile application using the React Native framework.",
				"Implemented CRUD HTTP requests using Axios package",
			],
		},
	};

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Tabs
				orientation={!isHorizontal ? "vertical" : "horizontal"}
				variant={isHorizontal ? "fullWidth" : "scrollable"}
				value={value}
				onChange={handleChange}
				className={classes.tabs}
			>
				{Object.keys(experienceItems).map((key, i) => (
					<Tab
						label={isHorizontal ? `0${i + 1}.` : key}
						{...a11yProps(i)}
					/>
				))}
			</Tabs>
			{Object.keys(experienceItems).map((key, i) => (
				<TabPanel
					value={value}
					index={i}
				>
					<span className="joblist-job-title">
						{experienceItems[key]["jobTitle"] + " "}
					</span>
					<a
						href={experienceItems[key]["link"]}
						target="_blank"
						rel="noreferrer"
					>
						<span className="joblist-job-company">{key}</span>
					</a>
					<div className="joblist-duration">
						{experienceItems[key]["duration"]}
					</div>
					<ul className="job-description">
						{experienceItems[key]["desc"].map(function (
							descItem: any,
							i: number,
						) {
							return (
								<FadeInSection delay={`${i + 1}00ms`}>
									<li key={i}>{descItem}</li>
								</FadeInSection>
							);
						})}
					</ul>
				</TabPanel>
			))}
		</div>
	);
};

export default JobList;
