import React, { useEffect, useState } from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles/SpaceScene.css";

const SpaceSceneSketch = (p5: any, windowWidth: any) => {
	const baseUrl = process.env.PUBLIC_URL;

	let stars: any = [];
	let angle = 0;
	let moonAngle = 0;
	let asteroids: any = [];
	let asteroidTimers: any = [];
	let earthTexture: any;
	let moonTexture: any;
	let userAngleX = 0; // Track rotation angle around X-axis
	let userAngleY = 0; // Track rotation angle around Y-axis
	let previousMouseX = 0; // Track previous mouse X position
	let previousMouseY = 0; // Track previous mouse Y position
	let isDragging = false; // Flag to track if the user is dragging

	p5.preload = () => {
		earthTexture = p5.loadImage(baseUrl + "/assets/earth_texture.jpg");
		moonTexture = p5.loadImage(baseUrl + "/assets/moon_texture.jpg");
	};

	p5.setup = () => {
		p5.createCanvas(window.innerWidth, 400, p5.WEBGL);
		p5.angleMode(p5.DEGREES);

		// Create stars
		for (let i = 0; i < 100; i++) {
			stars.push({
				x: p5.random(-p5.width, p5.width),
				y: p5.random(-p5.height, p5.height),
				size: p5.random(0.2, 2),
			});
		}

		// Initial asteroids with timers
		for (let i = 0; i < 3; i++) {
			asteroids.push(null);
			asteroidTimers.push(p5.random(100, 400)); // Delay for each asteroid
		}
	};

	p5.draw = () => {
		p5.clear();
		p5.noStroke();

		// Apply user-controlled rotation to the entire scene
		p5.push();
		p5.rotateX(userAngleX);
		p5.rotateY(userAngleY);

		// Draw and move stars
		for (let star of stars) {
			p5.push();
			p5.translate(star.x, star.y, star.z);
			p5.fill(255, 255, 255);
			p5.ellipse(0, 0, star.size);
			p5.pop();

			// Move star to the right and closer
			star.x += 1; // Adjust speed as needed
			star.z += 1;

			// Reset star position when it goes out of view
			if (star.x > p5.width / 2 || star.z > p5.width / 2) {
				star.x = p5.random(-p5.width, -p5.width / 2);
				star.z = p5.random(-p5.width, -p5.width / 2);
			}
		}

		// Set up a light source to imitate the sun
		p5.pointLight(255, 255, 255, -200, 0, 300); // White light from top-left
		p5.ambientLight(50); // Ambient light to slightly illuminate the dark side

		// Draw and revolve the main planet (Earth) with texture and glow
		p5.push();
		p5.rotateY(angle);
		p5.texture(earthTexture);
		p5.sphere(windowWidth >= 1024 ? 150 : 100); // Earth
		p5.pop();

		// Draw bluish glow around the Earth
		p5.push();
		p5.rotateY(angle);
		p5.noFill();
		p5.stroke(55, 134, 147, 5); // Custom bluish transparent stroke
		p5.strokeWeight(1);
		for (
			let r = windowWidth >= 1024 ? 151 : 101;
			r <= (windowWidth >= 1024 ? 157 : 107);
			r += 0.7
		) {
			p5.emissiveMaterial(55, 134, 147, 50);
			p5.sphere(r); // Draw slightly larger spheres for a soft glow effect
		}
		p5.pop();

		// Draw and revolve the moon around the Earth
		p5.push();
		p5.rotateY(angle);
		p5.translate(windowWidth >= 1024 ? 600 : 500, 0, 0); // Reduced distance for faster rendering
		p5.rotateY(moonAngle);
		p5.texture(moonTexture);
		p5.sphere(windowWidth >= 1024 ? 20 : 15);
		p5.fill(200, 200, 200);
		drawMoon(p5); // Custom moon pattern
		p5.pop();

		// Draw asteroids
		for (let i = 0; i < asteroids.length; i++) {
			let asteroid = asteroids[i];
			if (asteroid) {
				p5.push();
				p5.translate(asteroid.x, asteroid.y, asteroid.z);

				// Draw the glowing effect
				p5.noFill();
				for (let j = 0; j < asteroid.prevPositions.length; j++) {
					let pos = asteroid.prevPositions[j];
					p5.push();
					p5.translate(pos.x, pos.y, pos.z);
					p5.stroke(150, 150, 150, (255 * j) / asteroid.prevPositions.length);
					drawAsteroid(
						p5,
						asteroid.size + (asteroid.prevPositions.length - j) * 0.5,
					);
					p5.pop();
				}

				// Draw the asteroid itself
				p5.noStroke();
				p5.fill(150);
				drawAsteroid(p5, asteroid.size);
				p5.pop();

				// Update asteroid position
				asteroid.prevPositions.push({
					x: asteroid.x,
					y: asteroid.y,
					z: asteroid.z,
				});
				if (asteroid.prevPositions.length > 10) {
					asteroid.prevPositions.shift();
				}

				asteroid.x += asteroid.vx;
				asteroid.y += asteroid.vy;
				asteroid.z += asteroid.vz;

				// Reset asteroid if it goes out of view
				if (
					asteroid.x > p5.width / 2 ||
					asteroid.x < -p5.width / 2 ||
					asteroid.y > p5.height / 2 ||
					asteroid.y < -p5.height / 2 ||
					asteroid.z > p5.width / 2 ||
					asteroid.z < -p5.width / 2
				) {
					asteroids[i] = null;
					asteroidTimers[i] = p5.random(100, 500);
				}
			} else if (asteroidTimers[i] > 0) {
				asteroidTimers[i]--;
			} else {
				asteroids[i] = createAsteroidWithTrail();
			}
		}

		// Increment the angle for rotation
		angle += 0.1;
		moonAngle += 1;

		p5.pop(); // Restore the original transformation matrix
	};

	p5.mousePressed = () => {
		// Initialize the previous mouse positions when the user starts dragging
		previousMouseX = p5.mouseX;
		previousMouseY = p5.mouseY;
		isDragging = true;
	};

	p5.mouseDragged = () => {
		if (isDragging) {
			// Calculate the difference in mouse position
			let dx = p5.mouseX - previousMouseX;
			let dy = p5.mouseY - previousMouseY;

			// Update the rotation angles based on the mouse movement
			userAngleY += dx * 0.5;
			userAngleX -= dy * 0.5;

			// Update the previous mouse position
			previousMouseX = p5.mouseX;
			previousMouseY = p5.mouseY;
		}
	};

	p5.mouseReleased = () => {
		// Stop dragging when the mouse is released
		isDragging = false;
	};

	function drawMoon(p5: any) {
		p5.push();
		for (let i = 0; i < 5; i++) {
			p5.rotateY(p5.random(0, p5.TWO_PI));
			p5.rotateX(p5.random(0, p5.TWO_PI));
			p5.fill(p5.random(50, 255));
			p5.sphere(5); // Moon craters
		}
		p5.pop();
	}

	function createAsteroid() {
		return {
			x: p5.random(-p5.width, p5.width),
			y: p5.random(-p5.height, p5.height),
			z: p5.random(-p5.width, p5.width),
			vx: p5.random(-1, 1), // Reduced speed for smoother animation
			vy: p5.random(-1, 1),
			vz: p5.random(-1, 1),
			size: p5.random(10, 20),
		};
	}

	function createAsteroidWithTrail() {
		let asteroid = createAsteroid();
		let prevPositions = [];
		for (let i = 0; i < 10; i++) {
			prevPositions.push({
				x: asteroid.x - asteroid.vx * i,
				y: asteroid.y - asteroid.vy * i,
				z: asteroid.z - asteroid.vz * i,
			});
		}
		return { ...asteroid, prevPositions };
	}

	function drawAsteroid(p5: any, size: any) {
		p5.beginShape();
		for (let i = 0; i < 6; i++) {
			let angle = (p5.TWO_PI / 6) * i;
			let x = p5.cos(angle) * size + p5.random(-size * 0.3, size * 0.3);
			let y = p5.sin(angle) * size + p5.random(-size * 0.3, size * 0.3);
			p5.vertex(x, y);
		}
		p5.endShape(p5.CLOSE);
	}
};

const SpaceScene = () => {
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Initialize width

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<div id="space-scene">
			<ReactP5Wrapper
				sketch={(sketch) => SpaceSceneSketch(sketch, windowWidth)}
			/>
		</div>
	);
};

export default SpaceScene;
