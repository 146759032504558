import React from "react";
import "../styles/Projects.css";

import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Typist from "react-typist";
import "../styles/Intro.css";

const Projects = () => {
	// const spotlightProjects: any = {
	// 	Portfolio: {
	// 		title: "",
	// 		desc: "",
	// 		techStack: "",
	// 		link: "",
	// 		open: "",
	// 		image: "",
	// 	},
	// };

	// const projects: any = {
	// 	"Sample Project": {
	// 		desc: "",
	// 		techStack: "",
	// 		link: "",
	// 		open: "",
	// 	},
	// };

	return (
		<div id="projects">
			<div className="section-header ">
				<span className="section-title">/ projects</span>
			</div>
			<FadeInSection>
				<Typist avgTypingDelay={170}>
					<span className="coming-soon">{"coming soon..."}</span>
				</Typist>
			</FadeInSection>

			{/* <Carousel>
				{Object.keys(spotlightProjects).map((key, i) => (
					<Carousel.Item key={i}>
						<img
							className="d-block w-100"
							src={spotlightProjects[key]["image"]}
							alt={key}
						/>
						<div className="caption-bg">
							<Carousel.Caption>
								<h3>{spotlightProjects[key]["title"]}</h3>
								<p>
									{spotlightProjects[key]["desc"]}
									<p className="techStack">
										{spotlightProjects[key]["techStack"]}
									</p>
								</p>
								<ExternalLinks
									githubLink={spotlightProjects[key]["link"]}
									openLink={spotlightProjects[key]["open"]}
								/>
							</Carousel.Caption>
						</div>
					</Carousel.Item>
				))}
			</Carousel>
			<div className="project-container">
				<ul className="projects-grid">
					{Object.keys(projects).map((key, i) => (
						<FadeInSection
							key={i}
							delay={`${i + 1}00ms`}
						>
							<li className="projects-card">
								<div className="card-header">
									<div className="folder-icon">
										<FolderOpenRoundedIcon style={{ fontSize: 35 }} />
									</div>
									<ExternalLinks
										githubLink={projects[key]["link"]}
										openLink={projects[key]["open"]}
									/>
								</div>

								<div className="card-title">{key}</div>
								<div className="card-desc">{projects[key]["desc"]}</div>
								<div className="card-tech">{projects[key]["techStack"]}</div>
							</li>
						</FadeInSection>
					))}
				</ul>
			</div> */}
		</div>
	);
};

export default Projects;
