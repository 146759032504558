import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";

import FadeInSection from "./FadeInSection";
import SpaceScene from "./ SpaceScene";

const Intro = () => {
	return (
		<div id="intro">
			<SpaceScene />
			<Typist avgTypingDelay={170}>
				<span className="intro-title">
					{"hi, "}
					<span className="intro-name">{"Davis"}</span>
					{" here."}
				</span>
			</Typist>
			<FadeInSection>
				<div className="intro-subtitle">I create</div>
				<div className="intro-desc">
					I'm a software engineer from Tanzania. I'm here to bring a little bit
					of wow to your digital universe
				</div>
				<a
					href="mailto:davistibz@gmail.com"
					className="intro-contact"
				>
					<EmailRoundedIcon />
					{" Say hi!"}
				</a>
			</FadeInSection>
		</div>
	);
};

export default Intro;
